import { useEffect, useState } from "react";
import Nav from "../Nav/nav";
import { Helmet } from "react-helmet";
function Blog() {
  // make a call to the backend to get the list of blogs
  // display the list of blogs
  // each blog should have a title, date, author, and a short description

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [blogs, setBlogs] = useState([]);

  interface Blog {
    title: string;
    description: string;
    slug: string;
  }
  useEffect(() => {
    fetch(`${API_ENDPOINT}api/blogs`)
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Nav />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs | Ankigenerator</title>
        <link rel="canonical" href="http://ankigenerator.app" />
      </Helmet>
      <div className="flex flex-wrap justify-center">
        <div className="card w-10/12 md:w-1/2 lg:w-1/3 mt-16 mb-8 md:mb-0 bg-white text-black p-8">
          {blogs.map((blog: Blog) => (
            <div key={blog.title}>
              <a href={blog.slug}>
                <h2 className="card-title underline">{blog.title}</h2>
              </a>
              <p className=" mt-6 text-gray-800 text-base">
                {blog.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Blog;
