import "./nav.scss";

function Nav() {
  return (
    <div className="flex p-5 navbar justify-start align-bottom">
      <a href="/" className="font-bold text-2xl self-end">
        Ankigenerator
      </a>
      <a href="/pricing" className="ml-4 text-lg self-end">
        Pricing
      </a>
    </div>
  );
}

export default Nav;
