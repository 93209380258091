import { create } from "zustand";
import { QuestionDeck, TopicUrl } from "./type";
// Define Zustand store

interface QuestionDeckStore {
  apiData: QuestionDeck | null;
  setApiData: (data: QuestionDeck) => void;
}
export const useStore = create<QuestionDeckStore>()((set) => ({
  apiData: null,
  setApiData: (data: QuestionDeck) => set({ apiData: data }),
}));

interface TopicUrlStore {
  topicUrl: TopicUrl | null;
  setTopicUrl: (data: TopicUrl) => void;
}

export const useTopicUrlStore = create<TopicUrlStore>((set) => ({
  topicUrl: null,
  setTopicUrl: (data: TopicUrl) => set({ topicUrl: data }),
}));
