import Nav from "../Nav/nav";
import { useEffect, useState } from "react";
import axios from "axios";
import { useStore, useTopicUrlStore } from "../datastore";
import { QuestionAnswer, QuestionDeck } from "../type";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Question() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const setApiData = useStore((state) => state.setApiData);
  const data = useStore((state) => state.apiData);
  const setTopicUrl = useTopicUrlStore((state) => state.setTopicUrl);
  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    if (!data) {
      navigate("/404-not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);
    axios
      .post(`${API_ENDPOINT}api/submit`, data)
      .then((res) => {
        setTopicUrl({ url: res.data.url, topic: res.data.topic });
        setIsDisabled(false);
        setIsLoading(false);
        navigate("/download");
      })
      .catch((err) => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  };

  const handleChange = (
    index: number,
    field: keyof QuestionAnswer,
    value: string
  ) => {
    // Update the data object with the changed value
    if (data) {
      const updatedData: QuestionDeck = { ...data };
      if (updatedData.question_list && updatedData.question_list[index]) {
        updatedData.question_list[index][field] = value;
        setApiData(updatedData);
      }
    }
  };

  const QuestionAnswerComponent = ({
    questionAnswer,
    index,
  }: {
    questionAnswer: QuestionAnswer;
    index: number;
  }) => {
    return (
      <div className="w-11/12 md:w-10/12 justify-content-center mx-auto mt-8">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Questions | Ankigenerator</title>
          <link rel="canonical" href="http://ankigenerator.app" />
        </Helmet>
        <label className="input input-bordered flex items-center gap-2 text-black dark:text-white">
          <kbd className="kbd kbd-sm">{index + 1}</kbd>
          <input
            type="text"
            placeholder="Type here"
            value={questionAnswer.question}
            className="input input-bordered w-full text-black dark:text-white"
            onChange={(e) => handleChange(index, "question", e.target.value)}
          />
        </label>
        <textarea
          placeholder="Type here"
          value={questionAnswer.answer}
          className="textarea textarea-bordered w-full mt-2 text-black dark:text-white"
          onChange={(e) => handleChange(index, "answer", e.target.value)}
        ></textarea>
      </div>
    );
  };

  return (
    <>
      <Nav />
      {/* render data */}
      {data && (
        <form onSubmit={handleSubmit}>
          <div>
            <div className="flex w-11/12 md:w-10/12 justify-between mx-auto mt-10">
              <div className="text-2xl self-end">{data.topic}</div>
              <button
                className="button-positive rounded-lg ml-2 w-32 self-end p-2 md:p-4"
                disabled={isDisabled}
              >
                {isLoading ? (
                  <span className="loading"></span>
                ) : (
                  <span>Generate</span>
                )}
              </button>
            </div>
            {data.question_list.map((questionAnswer, index) => (
              <QuestionAnswerComponent
                key={index}
                questionAnswer={questionAnswer}
                index={index}
              />
            ))}
          </div>
        </form>
      )}
    </>
  );
}

export default Question;
