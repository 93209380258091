import Nav from "../Nav/nav";
import { Helmet } from "react-helmet";

function Pricing() {
  const freeTierFeatures = ["Generate unlimited number of Anki decks"];
  const paidTierFeatures = [
    "Generate unlimited number of Anki decks",
    "Generate Anki deck from any webpage",
    "Generate Anki deck with and from images",
    "More fidelity in the Anki decks you are generating",
    "No limit on the number of questions to be generated",
  ];

  const generateCheckmarkList = (features: string[]) => {
    return features.map((feature, index) => (
      <div key={index} className="flex justify-between">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Pricing | Ankigenerator</title>
          <link rel="canonical" href="http://ankigenerator.app" />
        </Helmet>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>

        <p>{feature}</p>
      </div>
    ));
  };

  return (
    <>
      <Nav />

      <div className="flex flex-wrap justify-center">
        <div className="card w-10/12 md:w-1/2 lg:w-1/4 mt-16 mb-8 md:mb-0 bg-white text-black">
          <div className="card-body">
            <h2 className="card-title text-4xl">
              $0{" "}
              <span className="text-gray-400 text-base self-end">
                USD - Billed Monthly
              </span>
            </h2>
            {generateCheckmarkList(freeTierFeatures)}
          </div>
        </div>
        <div className="card w-10/12 md:w-1/2 lg:w-1/4 text-black mt-16 ml-0 md:ml-8 bg-white">
          <div className="card-body">
            <h2 className="card-title text-4xl">
              $5{" "}
              <span className="text-gray-400 text-base self-end">
                USD - Billed Monthly
              </span>
            </h2>
            {generateCheckmarkList(paidTierFeatures)}
            <div className="card-actions ">
              <a
                href="https://airtable.com/appg1pmzvJIs6dLhw/shrjK3glUQjiRA4mE"
                className="button-positive rounded-lg p-2 mt-4"
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
