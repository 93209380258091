import Nav from "../Nav/nav";
import { useEffect, useState } from "react";
import UploadLogo from "./UploadLogo.svg";
import axios from "axios";
import { useStore } from "../datastore";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  const [uploadText, setUploadText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setISDisabled] = useState(false);
  const setApiData = useStore((state) => state.setApiData);
  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    axios.post(`${API_ENDPOINT}api/generate`, { topic: uploadText }).then(
      (response) => {
        setApiData(response.data);
        setIsLoading(false);
        navigate("/question");
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };
  const handleClick = (text: string) => {
    setUploadText(text);
  };

  useEffect(() => {
    if (uploadText === "") {
      setISDisabled(true);
    } else {
      setISDisabled(false);
    }
  }, [uploadText]);

  const examples = [
    "US states and its Capitals",
    "Common German words for Greetings",
    "Panama Canal History",
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Generate Anki Decks | Ankigenerator</title>
        <link rel="canonical" href="http://ankigenerator.app" />
        <meta
          name="description"
          content="Generate Anki decks in 30 seconds with AI"
        />
      </Helmet>
      <Nav />
      <div>
        <h1 className="text-3xl md:text-4xl text-center mt-12 w-4/5 md:w-1/2 mx-auto">
          Generate Anki decks in{" "}
          <span className="highlighttext underline">30 Seconds</span> with AI
        </h1>

        <form
          className="flex justify-center mt-10 w-4/5 md:w-1/2 mx-auto"
          onSubmit={onSubmit}
        >
          <input
            type="text"
            value={uploadText}
            className="p-2 border border-gray-300 rounded-lg w-full text-black dark:text-white"
            placeholder="Enter the text here"
            onChange={(e) => setUploadText(e.target.value)}
          />
          <button
            className="button-positive rounded-lg ml-2 w-32"
            disabled={isDisabled}
          >
            {isLoading ? (
              <span className="loading"></span>
            ) : (
              <span>Generate</span>
            )}
          </button>
        </form>
      </div>
      <div className="w-4/5 md:w-1/2 mx-auto mt-10">
        <h2 className="text-2xl underline">Examples</h2>
        {examples.map((example, index) => (
          <span
            className="flex"
            key={index}
            onClick={() => handleClick(example)}
            style={{ cursor: "pointer" }}
          >
            <p className="mt-5 text-lg self-end mr-3">{example}</p>
            <img src={UploadLogo} alt="Upload Logo" className="self-end mb-1" />
          </span>
        ))}
      </div>
    </>
  );
}

export default Home;
