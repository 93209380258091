import Nav from "../Nav/nav";

function PageNotFound() {
  return (
    <>
      <Nav />

      <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
        <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
          <div className="relative">
            <div className="absolute">
              <div>
                <h1 className="my-2 text-white font-bold text-2xl">
                  Looks like you've found the doorway to the great nothing
                </h1>
                <p className="mt-2 mb-6 text-white">
                  Sorry about that! Please visit our homepage to get where you
                  need to go.
                </p>
                <a
                  href="/"
                  className="sm:w-full lg:w-auto mt-6 rounded md py-4 px-8 text-center button-positive"
                >
                  Take me there!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
