import Nav from "../Nav/nav";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useTopicUrlStore } from "../datastore";

function Download() {
  const topicUrl = useTopicUrlStore((state) => state.topicUrl);
  const navigate = useNavigate();
  useEffect(() => {
    if (!topicUrl) {
      navigate("/404-not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Nav />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Download | Ankigenerator</title>
        <link rel="canonical" href="http://ankigenerator.app" />
      </Helmet>

      <div className="card w-10/12 md:w-1/3 mt-16 mx-auto bg-white text-black">
        <div className="card-body">
          <h2 className="card-title">
            Your deck on '{topicUrl?.topic}'' is ready!
          </h2>
          <p>Click below to get your deck</p>
          <div className="card-actions justify-end">
            <a
              href={topicUrl?.url}
              download
              className="button-positive rounded-lg p-2"
            >
              Download Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Download;
